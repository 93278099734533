<template>
  <div>
    <div class="grid crud-demo">
      <div class="col-12">
        <div class="card">
          <h6 class="">Administração de Orçamentos</h6>
          <Toast/>
          <Toolbar class="mb-4">
            <template v-slot:start>
              <div class="my-2">
                <Button label="Novo" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" :disabled="true"/>
                <!--              <Button label="Delete" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedOrcamentos || !selectedOrcamentos.length" />-->
              </div>
            </template>

            <template v-slot:end>
              <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Importar" chooseLabel="Importar" class="mr-2 p-button-outlined" :disabled="true"/>
              <Button label="Exportar" icon="pi pi-download" class="p-button-outlined" @click="exportCSV($event)"  :disabled="true"/>
            </template>
          </Toolbar>

          <DataTable ref="dt" :value="orcamentos" v-model:selection="selectedOrcamentos" dataKey="id" :paginator="true" :rows="10"
                     v-model:filters="filters1" filterDisplay="menu" :loading="loading1" :filters="filters1"
                     paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords} orçamentos" responsiveLayout="scroll">
            <template #header>
              <div class="flex justify-content-between flex-column sm:flex-row">
                <Button type="button" icon="pi pi-filter-slash" label="Limpar Filtros" class="p-button-outlined mb-2" @click="clearFilter1()"/>
                <span class="p-input-icon-left mb-2">
                    <i class="pi pi-search" />
                    <InputText v-model="filters1['global'].value" placeholder="Pesquisar..." style="width: 100%"/>
                  </span>
              </div>
            </template>

            <!--          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>-->
            <Column field="id" header="Código" :sortable="true" filterField="id" :showFilterMatchModes="false" >
              <template #body="slotProps">
                <span class="p-column-title">Id</span>
                {{slotProps.data.id}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo código"/>
              </template>
            </Column>
            <Column field="cliente" header="Cliente" :sortable="true" filterField="cliente" >
              <template #body="slotProps">
                <span class="p-column-title">Cliente</span>
                {{slotProps.data.cliente}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo cliente"/>
              </template>
            </Column>

            <Column field="cidade" header="Cidade" :sortable="true" class="text-left" filterField="cidade">
              <template #body="slotProps">
                <span class="p-column-title">Cidade</span>
                {{formatCurrency(slotProps.data.cidade)}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pela cidade"/>
              </template>
            </Column>
            <Column field="estado" header="Estado" :sortable="true" class="text-right" filterField="estado">
              <template #body="slotProps">
                <span class="p-column-title">Estado</span>
                {{slotProps.data.estado}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pela estado"/>
              </template>
            </Column>
            <Column field="categ" header="Categoria" :sortable="true" filterField="categ">
              <template #body="slotProps">
                <span class="p-column-title">Categoria</span>
                {{slotProps.data.categ}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pela categoria"/>
              </template>
            </Column>

            <Column field="status" header="Status" :sortable="true" filterField="status">
              <template #body="slotProps">
                <span class="p-column-title">Status</span>
                <span :class="'orcamento-badge status-' + (slotProps.data.status ? slotProps.data.status.toLowerCase() : '')">{{slotProps.data.status}}</span>
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo status"/>
              </template>
            </Column>

            <Column header="Data" dataType="date" style="min-width:10rem">
              <template #body="slotProps">
                {{formatDate(slotProps.data.date)}}
              </template>
            </Column>

            <Column field="valortotal" header="Valor" :sortable="true" class="text-right" filterField="valortotal">
              <template #body="slotProps">
                <span class="p-column-title">Valor</span>
                {{formatCurrency(slotProps.data.valortotal)}}
              </template>
              <template #filter="{filterModel}">
                <InputNumber v-model="filterModel.value" mode="currency" currency="BRL" locale="pt-BR" />
              </template>
            </Column>

            <Column field="potencia" header="Potência" :sortable="true" class="text-right" filterField="potencia">
              <template #body="slotProps">
                <span class="p-column-title">Potência</span>
                {{formatDecimal(slotProps.data.potencia)}}
              </template>
              <template #filter="{filterModel}">
                <InputNumber v-model="filterModel.value" mode="decimal" :minFractionDigits="4" locale="pt-BR" />
              </template>
            </Column>


            <Column header="Ações" class="text-right">
              <template #body="slotProps">
                <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mr-2 mb-2" @click="confirmDeleteOrcamento(slotProps.data)" />
                <Button icon="pi pi-eye" class="p-button-rounded p-button-info mr-2 mb-2" @click="$router.push('orcamentopreview')" />
                <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2 mb-2" @click="$router.push('orcamentodetalhe/' + slotProps.data.id)" />
                <Button icon="pi pi-sort-amount-up" class="p-button-rounded p-button-help mr-2 mb-2" @click="visibleTimeline = true" />
              </template>
            </Column>
          </DataTable>




          <Dialog v-model:visible="orcamentoDialog" :style="{width: '450px'}" header="Detalhes do Orçamento" :modal="true" class="p-fluid">
            <!--          <img :src="'demo/images/orcamento/' + orcamento.image" :alt="orcamento.image" v-if="orcamento.image" width="150" class="mt-0 mx-auto mb-5 block shadow-2" />-->
            <div class="field">
              <label for="name">Cliente</label>
              <InputText id="name" v-model.trim="orcamento.name" required="true" autofocus :class="{'p-invalid': submitted && !orcamento.name}" />
              <small class="p-invalid" v-if="submitted && !orcamento.name">Cliente é obrigatório.</small>
            </div>
            <div class="field">
              <label for="description">Descrição</label>
              <Textarea id="description" v-model="orcamento.description" required="true" rows="3" cols="20" />
            </div>

            <div class="field">
              <label for="inventoryStatus" class="mb-3">Status</label>
              <Dropdown id="inventoryStatus" v-model="orcamento.inventoryStatus" :options="statuses" optionLabel="label" placeholder="Select a Status">
                <template #value="slotProps">
                  <div v-if="slotProps.value && slotProps.value.value">
                    <span :class="'orcamento-badge status-' +slotProps.value.value">{{slotProps.value.label}}</span>
                  </div>
                  <div v-else-if="slotProps.value && !slotProps.value.value">
                    <span :class="'orcamento-badge status-' +slotProps.value.toLowerCase()">{{slotProps.value}}</span>
                  </div>
                  <span v-else>
									{{slotProps.placeholder}}
								</span>
                </template>
              </Dropdown>
            </div>

            <div class="field">
              <label class="mb-3">Categoria</label>
              <div class="formgrid grid">
                <div class="field-radiobutton col-6">
                  <RadioButton id="category1" name="category" value="Accessories" v-model="orcamento.category" />
                  <label for="category1">Grandes Negócios</label>
                </div>
                <div class="field-radiobutton col-6">
                  <RadioButton id="category2" name="category" value="Clothing" v-model="orcamento.category" />
                  <label for="category2">Residencial</label>
                </div>
                <div class="field-radiobutton col-6">
                  <RadioButton id="category3" name="category" value="Electronics" v-model="orcamento.category" />
                  <label for="category3">Rural</label>
                </div>
                <div class="field-radiobutton col-6">
                  <RadioButton id="category4" name="category" value="Fitness" v-model="orcamento.category" />
                  <label for="category4">Comercial</label>
                </div>
              </div>
            </div>

            <div class="formgrid grid">
              <div class="field col">
                <label for="price">Valor</label>
                <InputNumber id="price" v-model="orcamento.price" mode="currency" currency="BRL" locale="pt-BR" />
              </div>
              <div class="field col">
                <label for="quantity">Potência</label>
                <InputNumber id="quantity" v-model="orcamento.quantity" integeronly />
              </div>
            </div>
            <template #footer>
              <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
              <Button label="Salvar" icon="pi pi-check" class="p-button-text" @click="saveOrcamento" />
            </template>
          </Dialog>

          <Dialog v-model:visible="deleteOrcamentoDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
            <div class="flex align-items-center justify-content-center">
              <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
              <span v-if="orcamento">Confirma a exclusão de <b>{{orcamento.name}}</b>?</span>
            </div>
            <template #footer>
              <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteOrcamentoDialog = false"/>
              <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteOrcamento" />
            </template>
          </Dialog>

          <Dialog v-model:visible="deleteOrcamentosDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
            <div class="flex align-items-center justify-content-center">
              <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
              <span v-if="orcamento">Tem certeza que deseja deletar os itens selecionados?</span>
            </div>
            <template #footer>
              <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteOrcamentosDialog = false"/>
              <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedOrcamentos" />
            </template>
          </Dialog>
        </div>
      </div>


    </div>
    <div class="grid p-fluid">
      <div class="col-12 lg:col-6">
        <div class="card">
          <h6>Últimos Meses (Potência)</h6>
          <Chart type="bar" :data="comboData" :options="comboOptions" />

        </div>
      </div>
      <div class="col-12 lg:col-6">
        <div class="card flex flex-column align-items-center">
          <h6>Valor Acumulado Estados (R$)</h6>
          <Chart type="polarArea" :data="polarData" :options="polarOptions" />
        </div>
      </div>
    </div>


    <div class="col-12 lg:col-12">
      <Sidebar v-model:visible="visibleTimeline" :baseZIndex="1000" position="right">
        <h3 style="font-weight: normal">Timeline</h3>
        <orcamentos-list-timeline></orcamentos-list-timeline>
      </Sidebar>
    </div>
  </div>

</template>

<script>
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import OrcamentoService from '@/service/OrcamentoService';
import OrcamentosListTimeline from "@/components_esol/orcamentos/OrcamentosListTimeline";


export default {
  components: {
    OrcamentosListTimeline

  },
  data() {
    return {
      visibleTimeline: false,
      loading1: true,
      filters1: null,
      orcamentos: null,
      orcamentoDialog: false,
      deleteOrcamentoDialog: false,
      deleteOrcamentosDialog: false,
      orcamento: {},
      selectedOrcamentos: null,
      filters: {},
      submitted: false,
      statuses: [
        {label: 'QUALIFICADO', value: 'qualificado'},
        {label: 'ANÁLISE', value: 'análise'},
        {label: 'DESQUALIFICADO', value: 'desqualificado'}
      ],

      categorias: [
        {name: "Rural" },
        {name: "Residencial" },
        {name: "Grandes Negócios" },
      ],


      barData: {
        labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho'],
        datasets: [
          {
            label: 'Elaboração',
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            data: [65, 59, 80, 81, 56, 55, 40]
          },
          {
            label: 'Convertido',
            backgroundColor: 'rgb(54, 162, 235)',
            borderColor: 'rgb(54, 162, 235)',
            data: [28, 48, 40, 19, 86, 27, 90]
          }
        ]
      },
      barOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#A0A7B5'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#A0A7B5'
            },
            grid: {
              color:  'rgba(160, 167, 181, .3)',
            }
          },
          y: {
            ticks: {
              color: '#A0A7B5'
            },
            grid: {
              color:  'rgba(160, 167, 181, .3)',
            }
          },
        }
      },


      radarData: {
        labels: ['GD1', 'GD0', 'MD1', 'MD0', 'PQ2', 'PQ1', 'PQ0'],
        datasets: [
          {
            label: 'ES',
            backgroundColor: 'rgba(54, 162, 235,0.2)',
            borderColor: 'rgba(54, 162, 235,1)',
            pointBackgroundColor: 'rgba(54, 162, 235,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(54, 162, 235,1)',
            data: [65, 59, 90, 81, 56, 55, 40]
          },
          {
            label: 'MG',
            backgroundColor: 'rgba(255, 99, 132,0.2)',
            borderColor: 'rgba(255, 99, 132,1)',
            pointBackgroundColor: 'rgba(255, 99, 132,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(255, 99, 132,1)',
            data: [28, 48, 40, 19, 96, 27, 100]
          }
        ]
      },

      radarOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#A0A7B5'
            }
          }
        },
        scales: {
          r: {
            grid: {
              color: 'rgba(160, 167, 181, .3)'
            }
          }
        }
      },


      pieData: {
        labels: ['GD', 'MD', 'PQ'],
        datasets: [
          {
            data: [540, 325, 702, 421],
            backgroundColor: [
              'rgb(54, 162, 235)',
              'rgb(255, 99, 132)',
              'rgb(255, 205, 86)',
              'rgb(75, 192, 192)'
            ]
          }]
      },
      pieOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#A0A7B5'
            }
          }
        }
      },


      lineData: [],
      lineOptions: [],

      polarData: [],
      polarOptions: [],


      comboData: [],
      comboOptions: []

    }
  },
  orcamentoService: null,
  created() {
    this.orcamentoService = new OrcamentoService();
    this.initFilters();
  },
  mounted() {

    this.orcamentoService.getTesteApi().then((data) => {
      console.log('getTesteApi')
      console.log(data)

    })

    this.orcamentoService.getssorctoindex().then((data) => {
      console.log('getssorctoindex mounted')
      // console.log(data)
      this.loading1 = false
      this.orcamentos = data[0]
      let labelsPolarChart = []

      this.orcamentos.forEach((orcamento) => {
        orcamento.date = new Date(orcamento.created_at)
        labelsPolarChart.push(orcamento.estado)
      });
      labelsPolarChart = [...new Set(labelsPolarChart)]

      let valuesPolarData = []
      labelsPolarChart.forEach((label) => {
        // console.log(label)
        let orcsFiltr =  this.orcamentos.filter(x => x.estado === label)
        let valorTot = 0
        orcsFiltr.forEach((orcto) => {
          if (orcto.valortotal)
            valorTot += orcto.valortotal
          //console.log(orcto.valortotal)
        })
        valuesPolarData.push(valorTot)
        // console.log('totalLabel')
        // console.log(totalLabel)
      })

      // let totalLabelRJ =  this.orcamentos.filter(x => x.estado === 'RJ').length
      // console.log('totalLabelRJ')
      // console.log(totalLabelRJ)

      let labelsLinesChart = data[1]
      let valuesLines1Chart = data[2]
      let valuesLines2Chart = data[3]


      this.comboData = {
        labels: labelsLinesChart,
        datasets: [{
          type: 'line',
          label: 'Tendência',
          borderColor: '#42A5F5',
          borderWidth: 2,
          fill: false,
          data: valuesLines1Chart
        }, {
          type: 'bar',
          label: 'Confirmado',
          backgroundColor: '#66BB6A',
          data: valuesLines1Chart,
          borderColor: 'white',
          borderWidth: 2
        }, {
          type: 'bar',
          label: 'Elaboração',
          backgroundColor: '#FFA726',
          data: valuesLines2Chart
        }]
      }

      this.comboOptions = {
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          },
          y: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          }
        }
      }

      this.polarData = {
        datasets: [{
          data: valuesPolarData,
          backgroundColor: ["#42A5F5","#66BB6A","#FFA726","#26C6DA","#7E57C2"],
          label: 'My dataset'
        }],
            labels: labelsPolarChart
      }

      this.polarOptions = {
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        },
        scales: {
          r: {
            grid: {
              color: '#ebedef'
            }
          }
        }
      }


    })


  },
  methods: {
    formatDecimal(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 4, maximumFractionDigits: 4});
      return;
    },
    formatCurrency(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
      return;
    },
    openNew() {
      this.$router.push({ path: '/orcamentodetalhe/0' });
    },
    hideDialog() {
      this.orcamentoDialog = false;
      this.submitted = false;
    },
    saveOrcamento() {
      this.submitted = true;
      if (this.orcamento.name.trim()) {
        if (this.orcamento.id) {
          this.orcamento.inventoryStatus = this.orcamento.inventoryStatus.value ? this.orcamento.inventoryStatus.value: this.orcamento.inventoryStatus;
          this.orcamentos[this.findIndexById(this.orcamento.id)] = this.orcamento;
          this.$toast.add({severity:'success', summary: 'Successo', detail: 'Orçamento Atualizado', life: 3000});
        }
        else {
          this.orcamento.id = this.createId();
          this.orcamento.code = this.createId();
          this.orcamento.image = 'orcamento-placeholder.svg';
          this.orcamento.inventoryStatus = this.orcamento.inventoryStatus ? this.orcamento.inventoryStatus.value : 'ANÁLISE';
          this.orcamentos.push(this.orcamento);
          this.$toast.add({severity:'success', summary: 'Successo', detail: 'Orçamento Criado', life: 3000});
        }
        this.orcamentoDialog = false;
        this.orcamento = {};
      }
    },
    editOrcamento(orcamento) {


      // this.orcamento = {...orcamento};
      // this.orcamentoDialog = true;

      this.$router.push({ path: `/orcamento/${orcamento.id}` });


    },
    confirmDeleteOrcamento(orcamento) {
      this.orcamento = orcamento;
      this.deleteOrcamentoDialog = true;
    },
    deleteOrcamento() {
      this.orcamentos = this.orcamentos.filter(val => val.id !== this.orcamento.id);
      this.deleteOrcamentoDialog = false;
      this.orcamento = {};
      this.$toast.add({severity:'success', summary: 'Successo', detail: 'Orçamento Deletado', life: 3000});
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.orcamentos.length; i++) {
        if (this.orcamentos[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = '';
      var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for ( var i = 0; i < 5; i++ ) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteOrcamentosDialog = true;
    },
    deleteSelectedOrcamentos() {
      this.orcamentos = this.orcamentos.filter(val => !this.selectedOrcamentos.includes(val));
      this.deleteOrcamentosDialog = false;
      this.selectedOrcamentos = null;
      this.$toast.add({severity:'success', summary: 'Successo', detail: 'Orçamentos Deletados', life: 3000});
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }

      this.filters1 = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},

        'id': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'cliente': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'cidade': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'estado': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'categ': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'status': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'valortotal': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        'potencia': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},

      }

    },

    clearFilter1() {
      this.initFilters();
    },

    formatDate(value) {
      return value.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    },
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/styles/badges.scss';
</style>
